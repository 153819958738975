  /*
* 性别
* */
export const sexTypes =[
    {label: "男", value: 0},
    {label: "女", value: 1},
   
];
  /*
* 会场类别 
* */
export const placeType =[
  {label: "大会场", value: 0},
  {label: "小会议室", value: 1},
 
];