<template>
  <div class="pageMain">
    <search-head @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="所属酒店：">
          <el-select
              size="small"
              clearable
              v-model="searchForm.hotelId"
              placeholder="请选择所属酒店">
            <el-option
                v-for="item in hotelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会场名称：">
          <el-input
              size="small"
              clearable
              v-model="searchForm.placeName"
              placeholder="请输入会场名称"
          ></el-input>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
        :data="tableData"
        :total="total"
        noimport="true"
        isdel="true"
        title="会场列表"
        noexport="false"
        @selectionChange="selectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        @tableExport="tableExport"
        @tableDel="tableDel"
        @tableAdd="tableAdd">
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column prop="placeName" label="会场名称" align="center">
      </el-table-column>
      <el-table-column prop="hotelName" label="所属酒店" align="center"></el-table-column>
      <el-table-column prop="placeArea" label="面积(m2)" align="center"></el-table-column>
      <el-table-column prop="placeCapacity" label="容纳人数(人)" align="center"></el-table-column>
      <el-table-column prop="placeType" label="类别" align="center"></el-table-column>
      <el-table-column prop="createTime" label="添加时间" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" link style="margin-right: 10px" @click="editItem(scope.row)">查看附件</el-button>
          <el-button type="text" link style="margin-right: 10px" @click="delItem(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </custom-tables>
    <venueManageEdit
        :visible.sync="showDialog"
        :title="dialogTitle"
        :is-edit="isEdit"
        :item="item"
        @close="showDialog = false"
        @done="editDone"
    ></venueManageEdit>
  </div>
</template>

<script>
import basic from "../../../api/modules/basic";
import meeting from '../../../api/modules/meeting'
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import venueManageEdit from "../../../components/page/meeting/venueManageEdit.vue";
import {downloadFile} from "../../../utils/tool";

export default {
  name: "company",
  components: {venueManageEdit, SearchHead, CustomTables},
  data() {
    return {
      dialogTitle: "新增公司",
      showDialog: false,
      total: 0,
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      item: {},
      areaParentList: [],
      hotelList: [],
      isEdit: false
    };
  },
  mounted() {
    // this.getAreaInfo(0);
    this.gethotelList()
    this.getInfo();
  },
  methods: {
    getAreaInfo(id) {
      let param = {pid: id};
      basic.getAreaList(param).then((res) => {
        this.areaParentList = res.data;
      });
    },
    getInfo() {
      meeting.getMeetingPlacePageList(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = pageSize;
      this.getInfo();
    },
    editDone() {
      // 编辑成功返回
      this.showDialog = false;
      this.getInfo();
    },
    headReset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getInfo();
    },
    headSearch() {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getInfo();
    },
    tableDel() {
    },
    //导出
    tableExport() {
      let param = {
        currPage: this.searchForm.currPage,
        pageSize: this.searchForm.pageSize,
      };
      basic.getCompanyRecExport(param).then((res) => {
        downloadFile(res)
      }).catch(err => {
        console.log(err, 'err');
      })
    },
    //格式化json
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    tableAdd() {
      this.item = {
        annexList:[]
      };
      this.isEdit = false
      this.dialogTitle = "会场信息";
      this.showDialog = true;
    },
    selectionChange(val) {
      console.log(val);
    },
    editItem(item) {
      meeting.getMeetPlaceAnnex({placeId: item.placeId}).then(res =>{
        this.isEdit = true
        this.item = item;
        this.item.annexList = res.data
        this.dialogTitle = "详情";
        this.showDialog = true;
      })
    },
    delItem(item) {
      this.$confirm(`此操作将永久删除会议${item.placeName}, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        meeting.deleteMeetingPlaceInfo(item.placeId).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getInfo();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    gethotelList() {
      meeting.getHotelList().then(res => {
        res.data.map(item => {
          this.hotelList.push({
            label: item.hotelName,
            value: item.hotelId
          })
        })
      })
    },
  },
};
</script>

<style scoped></style>
  