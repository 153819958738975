<template>
  <custom-dialog :visible.sync="visible" :title="title" width="50%" @close="colseDialog" @submit="submit"
                 :is-footer='isEdit'>
    <el-form :model="editForm" label-width="120px" class="edit-form" :rules="rules"
             ref="editAcceptanceForm">
      <el-row :gutter="20" class="edit-form-row">
        <el-col :span="12">
          <el-form-item label="会场名称：" prop="placeName">
            <el-input size="small" v-model="editForm.placeName" placeholder="请输入会场名称"
                      :disabled="isEdit"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属酒店：" prop="hotelId">
            <el-select v-model="editForm.hotelId" size="small"
                       class="block-select"
                       placeholder="请选择所属酒店" :disabled="isEdit"
                       @change="hotelChange">
              <el-option
                  v-for="item in hotelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="房间编号：" prop="placeNo">
            <el-select v-model="editForm.placeNo" size="small"
                       class="block-select"
                       placeholder="请选择房间编号" :disabled="isEdit">
              <el-option
                  v-for="item in placeNoList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="面积(m2)：" prop="placeArea">
            <el-input size="small" v-model="editForm.placeArea" placeholder="请输入面积"
                      :disabled="isEdit"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="会场类别：" prop="placeType">
            <el-select v-model="editForm.placeType"
                       size="small"
                       class="block-select"
                       placeholder="请选择会场类别" :disabled="isEdit">
              <el-option
                  v-for="item in placeTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="容纳人数(人)：" prop="placeCapacity">
            <el-input size="small" v-model="editForm.placeCapacity" placeholder="请输入容纳人数"
                      :disabled="isEdit"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="line"></div>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注：" prop="placeNote">
            <el-input size="small" type="textarea" :rows="2"
                      v-model="editForm.placeNote" placeholder="请输入备注" :disabled="isEdit"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="附件：">
        <el-upload
            class="file-uploader"
            :disabled="isEdit"
            :action="`${uploadUrl}File/upload`"
            :data="{type:1}"
            :show-file-list="false"
            :on-success="uploadSuccess">
          <div class="upload-main">
                    <span class="upload-btn">
                      <i class="el-icon-upload"></i>上传附件
                    </span>
            仅支持上传图片、pdf格式
          </div>
          <div class="file-url-main">
            <div v-for="(url,index) in editForm.annexList" class="file-url-item" :key="index">
              <div class="file-url" @click.stop="showImg(url)">{{ url.annexUrl.match('--') ? url.annexUrl.split('--')[1] : url.annexUrl }}</div>
              <span class="file-url-btn el-icon-close" circle @click.stop="delImg(index)"></span>
            </div>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../common/CustomDialog";
import meeting from '../../../api/modules/meeting'
import {placeType} from '../../../utils/sexType'
import config from "../../../api/config";

export default {
  name: "managementEdit",
  components: {CustomDialog},
  props: ['visible', 'title', 'item', 'isEdit'],
  data() {
    return {
      editForm: {
        annexList: []
      },
      rules: {
        placeName: [
          {required: true, message: '请输入会场名称', trigger: 'blur'},
        ],
        hotelId: [
          {required: true, message: '请选择所属酒店', trigger: 'change'}
        ],
        placeNo: [
          {required: true, message: '请选择房间编号', trigger: 'change'}
        ],
        placeArea: [
          {required: true, message: '请输入工面积', trigger: 'blur'}
        ],
        placeType: [
          {required: true, message: '请选择会场类别', trigger: 'change'},
        ],
        placeCapacity: [
          {required: true, message: '请输入容纳人数', trigger: 'blur'}
        ],
      },
      uploadUrl: config.uploadUrl,
      projList: [],
      placeTypeList: placeType,
      hotelList: [],
      placeNoList: []
    }
  },
  mounted() {
    this.gethotelList()
  },
  watch:{
    item(newVal,oldVal){
      this.editForm = {...newVal}
    }
  },
  methods: {
    submit() {
      this.$refs.editAcceptanceForm.validate((valid) => {
        if (valid) {
          console.log(this.editForm);
          meeting.AddMeetingPlaceInfo(this.editForm).then(res => {
            this.$message({
              message: '新增成功',
              type: 'success'
            });
            this.$emit('done')
            this.resetForm()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    colseDialog() {
      this.$emit('close')
    },
    uploadSuccess(val) {
      console.log(val, '附件');
      // 附件
      if (val.code == 200) {
        this.editForm.annexList.push({
          annexUrl: val.data,
          annexType: 2
        })
      }
    },
    resetForm() {
      if (this.$refs.editAcceptanceForm) {
        this.$refs.editAcceptanceForm.clearValidate()
        this.$refs.editAcceptanceForm.resetFields()
      }
    },
    delImg(index) {
      this.editForm.annexList.splice(index, 1)
      this.$forceUpdate()
    },
    gethotelList() {
      meeting.getHotelList().then(res => {
        res.data.map(item => {
          this.hotelList.push({
            label: item.hotelName,
            value: item.hotelId
          })
        })
      })
    },
    hotelChange(val) {
      this.getplaceNo(val)
    },
    //会场房间编号查询
    getplaceNo(val) {
      let param = {
        hotelId: val
      }
      meeting.getMeetPlaceByHotelId(param).then(res => {
        this.placeNoList = []
        if (res.code == 200) {
          res.data.map(item => {
            this.placeNoList.push({
              label: item.placeNo,
              value: item.placeId
            })
          })
        }
      })
    },
    showImg(item){
      let html = `<div class="img-alert"><img src="${item.annexUrl}"/></div>`
      this.$alert(html, '图片', {
        dangerouslyUseHTMLString: true
      });

    }
  }
}
</script>

<style scoped lang="less">
.edit-form {
  padding: 20px;
}

.line {
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  margin-bottom: 15px;
}

.blankCol {
  height: 40px;
  margin-bottom: 20px;
}

.footer-save {
  position: absolute;
  bottom: 18px;
  right: 250px;
}

</style>